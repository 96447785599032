import axios from 'axios';

export function fetchEditions(listOnly = false, isCuratorExhibition = false) {
  return axios.get('/api/editions/')
    .then((response) => {
      const { results } = response.data;
      if(listOnly) return results.map(edition => ({id: edition.id, year: edition.year}));
      else return results.filter(edition => edition.is_exhibit === isCuratorExhibition).map(edition => {
        const { id, year, photos } = edition;
        const mainPhotoPath = photos.length && photos[0].path;
        return { id, year, mainPhotoPath };
      })
    });
}