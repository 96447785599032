import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { IntlProvider, addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_pl from 'react-intl/locale-data/pl';
import messages_en from './translations/en.json';
import messages_pl from './translations/pl.json';
import { getLanguage, changeLanguage } from './requests';

import Home from './views/Home';
import Editions from './views/Editions';
import Edition from './views/Edition';
import Authors from './views/Authors';
import Author from './views/Author';
import Artworks from './views/Artworks';
import Artwork from './views/Artwork';
import Laureates from './views/Laureates';
import Contact from './views/Contact';
import History from './views/History';
import Files from './views/Files';
import NotFound from './views/NotFound';

import Header from './components/Header';
import Footer from './components/Footer';

import Locations from './constants/Locations';

import './App.scss';

addLocaleData([...locale_en, ...locale_pl]);

const LANG_EN = 'en';
const LANG_PL = 'pl';

const messages = {
  [LANG_EN]: messages_en,
  [LANG_PL]: messages_pl
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: LANG_PL,
    };

    this.switchLang = this.switchLang.bind(this);
  }

  getOtherLang(lang) {
    return lang === LANG_PL ? LANG_EN : LANG_PL;
  }

  switchLang() {
    const { getOtherLang } = this;
    const { lang } = this.state;

    const otherLang = getOtherLang(lang);

    changeLanguage(otherLang).then(() => window.location.reload());
  }

  componentDidMount() {
    setTimeout(() => {
      getLanguage().then(lang => this.setState({lang: lang}));
    }, 500);
  }

  render() {
    const { lang } = this.state;
    const { getOtherLang, switchLang } = this;

    return (
      <div className='App-container'>
        <IntlProvider locale={lang} messages={messages[lang]}>
          <Router>
            <Header/>
            <div className='Main'>
              <Switch>
                <Route exact path={Locations.Home.path} component={Home} />
                <Route exact path={Locations.Editions.path} component={Editions}/>
                {Locations.Edition.toRoute({ component: Edition, invalid: NotFound }, true)}
                <Route exact path={Locations.Authors.path} component={Authors}/>
                {Locations.Author.toRoute({ component: Author, invalid: NotFound }, true)}
                <Route exact path={Locations.Artworks.path} component={Artworks}/>
                {Locations.Artwork.toRoute({ component: Artwork, invalid: NotFound }, true)}
                <Route exact path={Locations.Laureates.path} component={Laureates}/>
                <Route exact path={Locations.CuratorExhibitions.path} render={() => <Editions isCuratorExhibition/>}/>
                <Route exact path={Locations.Contact.path} component={Contact}/>
                <Route exact path={Locations.Files.path} component={Files}/>
                <Route exact path={Locations.History.path} component={History}/>
                <Route component={NotFound}/>
              </Switch>
            </div>
            <Footer otherLang={getOtherLang(lang)} switchLang={switchLang}/>
          </Router>
        </IntlProvider>
      </div>
    );
  }
}

export default App;
