import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Loader from '../../components/Loader';
import ServerError from '../../components/ServerError';

import { fetchPage } from '../../requests';

import './Contact.scss';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      loading: true
    };
  }

  componentDidMount() {
    fetchPage('contact').then((content) => this.setState({ content, loading: false }))
      .catch(error => this.setState({ serverError: true, loading: false }));
  };

  render() {
    const { content, loading, serverError } = this.state;
    return (
      <div className='Contact'>
        <div className='Contact__header'><FormattedMessage id='app.contactHeader'/></div>
        <div className='Contact__social-media'>
          <strong><FormattedMessage id='app.ourSocialMedia'/></strong>
          <div className='Contact__social-media-items'>
            <div className='Contact__social-media-item'>
              <a href='http://facebook.com/galeriabielska' target='_blank' rel='noopener noreferrer'>
                <img alt='Facebook' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABQVBMVEVHcEwCmuYDm+YAleoCmuQDm+UCnOQDm+UDm+UAgP8Cm+UDm+UAmeYCm+QFneQEmuUDm+UDm+UDnOUCm+UDmuQAougDnOQCm+UDmuUDm+YDm+UCm+YCm+YAnesAqv8Em+UAmOMDm+YDm+UDm+UCmuUDm+UDmuUGmucCm+QDm+UDnOYAneUCm+UAmuQDmuYAnOMCmuUGm+MCm+YGnOgAnucDm+W54/nk9Pzj9PyGz/ICm+UDm+X////X7/uS1PTu+P0UoueL0fNow+9Ruu0Mn+b1+/6T1PRQuu2p3fY+s+scpeiFz/Ko3Paa1/Vxx/APoOal2/YEm+XT7frF6PnD5/na8Pvv+f1Zve76/f75/f6K0fMlqOjU7vsmqemCzvL8/v8ip+grq+kIneY1r+q65PgNn+bg8/z4/P7+//8urOkLnuaX5jhLAAAAO3RSTlMAb6oMcutx++wEz/Iocy/V86hNzkwLS83xXMzScA0D1iX6+e5t+E4r0+1dJ9EmWyTQLm4sKqnv/f3m1Nf05hUAAAGMSURBVEjH7ZbnVsJAEIWTEJJQpUmvgoK910RRFHvvvff3fwBZ4OjuZDbxAbi/NrP3y5mZs5mNIHRE5JeloWKhUByTFL+9O+PL6ZS6ZIel3aX060Ba2sX3e906ovAgz98X0FF5Qrh/QNU5UhPo+7n+BhEx+/Me6Fq7e3m6OWitA15Tf0C9RxenlwbRRus5BnulsP7nTaOt1XZEZP3dTha4NiCgZRjAx/q3r0yALjNAjgX2m97znVqt9vh7SpjzBhq02wS22OAkPyP9hPiPQZAuWwJ7twQ4BMEgBaTA3iIB1kHQTQEaBiyBoJMCev4D9OLAfbWhBwJ8kFX1CwWolFYMRp9vaEopLvCOFy1xgVe8rTIXoCqfoIBx6rNZbuiMePfI6vtvp0wfpop9W5nDR+fEAxSrDwgBNDAC03ZACQ6BsDWQzMKxMRq3AsxjRhBCKh9Qo9joS6g8QB3Bh2vEgwPxKG98D8cwIJm3uFBEDQLOUtbyDnLICzRQURz211xZnJ6Zq9dn54PiVOcXoakfeMYD34HyOIIAAAAASUVORK5CYII='/>
              </a>
            </div>
            <div className='Contact__social-media-item'>
              <a href='http://twitter.com/GaleriaBielska' target='_blank' rel='noopener noreferrer'>
                <img alt='Twitter' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABcVBMVEVHcEwFqvUDqfQDqfQAov8EqfQCqfMDqfQA//8DqfQCqfUDqfQDqfMEqvQDqfUDqPMAgP8Aqv8DqfQFqPUAtv8ArPEDqfUDqfQEqfMCqfQDqvQDqfQAr+8DqPUDqfUGqvQDqfUDqvQAqvEDqfQDqfQAsesFqvQEqPQDqfMDqfQDqfQDqfQEqvMAqvIDqfQDqfQDqPMAp/UEqfMApO0DqPQDqfQEqfQEqfQCqfQDqvQDqPQAqvcEqfQCqvUCqPMAqv8ArPICqvMAquoCqvQDqPUDqPUDqfUDqfQEqPQDqfQDqvQCqfUDqfQDqPQDqvUCqvQDqfQEqfQDqfQDqPQDqvQDqPMDqPQDqfUDqvQDqvUDqfUAqvMFp/UFp/YDqPQDqfQFqPQEqfQDqfQCqfQCqfQDqvMAqvMApvQCqfQEqfMArfUDqPMDqfQDqvUGpvQEqfIDqPUDqfQEp/QDqfQApfADqfQDqfQDqfQDqfQDqfMDqfTwv3y5AAAAenRSTlMAM/qgC5Fu/gHwfPGYRUrdAgPuNQclTfaD0Mj1EGTzLay/Euz7DTCHr/3e40IntFyZGkEOYeGJ1nRa4B7Vfm0GKGwMzkmWYr1Gsrx/75xj0fjZ6J9gVVuUy0tQFTE3XrUvhvLTdlQqF3M+GVJZTi47k+1D9xHm68zBUwIFenwAAAFVSURBVEjH7ZVVd8MwDIWTLlmSMjOuMGbGjpmZmZnBv35du5PFiu29b71vlu5nKz6Sw3FF/TnViu0h05D/Mr+4hdlhOwjY/SmUVzTCpT8S9yA9qIgg0oBUvRnQSxlIWxEacWgDnUirZOb5EQcGctEJzS6yTetPxd8fYjhQ+hWvtqhrF8L1dANK8hbiFf3f6xbc/3oHLykdLWSkOq9bPfFH1/pLF9WkKd5R46nHgSs9ICwghsZ1/tXd7QADiOmAXM0+ul8SdECGVRDqIXRakAWYCQBvYABWUjOv0Ilkltj+rnUaUE6el82NpXmi39dIBtZoB7RRJnJrkexvbabN8I6T5K+00KfeM0YAepkPBT81CfwlMhOQR6eB38Gyu5fnQNMZafvv8xcnx3uHoJyqJure4YOQ7msVo8Aq5+z8CLMH+sK/vqWnszNBmyI5uxLmSHfx1/Lf9QmmcCDh9a4i7AAAAABJRU5ErkJggg=='/>
              </a>
            </div>
            <div className='Contact__social-media-item'>
              <a href='http://youtube.com/galeriabielska' target='_blank' rel='noopener noreferrer'>
                <img alt='YouTube' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABqlBMVEVHcEz0Qzb0QzX0Qjb1Qzb0Qzb1RDb1Qzb0QzbzQzb1RDf1QjX0Qzb0Qzb0RDbzQjf0Qzb////4gnn7wLz+8vL4gnr/+Pf+7ev+5uT95eP1UET7ubT1UEP//f35mJH93dv95OP1T0P5m5T7vrr93Nr+8vH+6+r0RDf8y8f8ysb93tz8z8z/9/b8w7/92tj2bWP//Pz8xcH0Rjn92NX6p6H2ZVr93Nn92db/+fn7w778zsr+9vb0Sj70SDz2XlP5l5D5k4z+8/L8x8P7vbn1U0f0RDj3e3L939z94+H2bGH7tK/+9PP4gXj5m5X95OL6q6X7wr74hn794N7//v7//v36sKv2bmT6raj809D8yMT6pqD1XFH2Zlv81NH3dGr2YVb6qaP6qqT5kIn8ycX3enH2a2H4job+7Ov5n5n4i4L5kYr2bGL7uLP6qaT6qKL4iID2Ylf1UUX2YFX2ZFn4jYb0RTj91tT1WE31W1D1WEz91dL3eXD80s/+7u31Ukf6oZv1Ukb1VUn3fHP2aV/1TkL2X1T7wb38zcn7vLf1V0v94uD/+/v7ta/4iYFKg8AhAAAAEHRSTlMA9L/ATL1L876FT03y8YiDj7Iz3QAAAeJJREFUSMft1ddX1EAYBXD6gqB+dxiSbGV3XXdZtrGNJl2qXSl2pdil2btiBdv/zJAc9GTJmOw5PnIf8jC5v5Q5k0xFxX7KyIHqSpKmsqqxtH+IbNJQcn2yTY0JVO8OaydjdEHptwD1JuDaHT6BgT5/3OoOTSbwZ/gUzs8hQLHRsT3CGnj8F4dQnIoi+pnajtIR2AHKX19+F0so4eSmQ3BFmdwidyd1BR2CG8DvskC/gu8UjFCXm+KcjtsDmhAdAfKOQbvo/Jig9CTFhynlEHzr+PUzRSO4POMQvHyrvB6gxeHZsw6ANP8D+ALeRERTOe+GSDfnqhZJeAM+GfC4YRm3RwLCxhsaLWr5K8ISkALLUIbtBT0S0Ao0UzMKWWQLAjxfuQssLYxMo1UCVAMQAyPQ1y/0BoO527khqBKQNIGMMvURvnHcCuGYBPhNoAUsph8JHU6BOBYLT+QgaQFeMcakj6RaAH2S1X9PqyiN6+DTGJ4xZOel09pjgNDjpzkB3n/YeIHiev7mVZyTgNMGuDN674EA99ceraLzYd+1S1ZLQ/9VeoLWi++MvlwPmkCVfo1QwNub1qKcKzs9hfOolu71Dob0k7Um0Gj//dSZN4gGu/7h0i2opt4lb7tq6/Y39XKyDVGWemWNS1hQAAAAAElFTkSuQmCC'/>
              </a>
            </div>
          </div>
        </div>
        {
          loading ? <Loader/> : (
            serverError ? <ServerError/> : (
              <div className='Contact__content' dangerouslySetInnerHTML={{__html: content}}></div>
            )
          )
        }
      </div>
    );
  }
}

export default Contact;