import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import FsLightbox from "fslightbox-react";

import Loader from "../../components/Loader";
import ServerError from "../../components/ServerError";

import { fetchAttachments, fetchAuthors, fetchEditions } from "../../requests";
import { SUBFOLDERS, SUBFOLDERSSRC } from "../../constants/Subfolders";

import "./Files.scss";
import queryString from "query-string";

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editions: [],
      edition: "",
      authors: [],
      author: "",
      images: [],
      attachments: [],
      subfolder: "",
      date_sort: true,
      loading: true,
      toggler: false,
      slide: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeSubfolder = this.changeSubfolder.bind(this);
    this.openLightboxOnSlide = this.openLightboxOnSlide.bind(this);
  }

  openLightboxOnSlide(slideNumber) {
    this.setState((currentState) => ({
      toggler: !currentState.toggler,
      slide: slideNumber,
    }));
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({ ...query });
    Promise.all([fetchAttachments(), fetchAuthors(true), fetchEditions(true)])
      .then(([attachments, authors, editions]) =>
        this.setState({
          attachments: attachments.results,
          authors,
          editions,
          loading: false,
        })
      )
      .catch(() => this.setState({ serverError: true, loading: false }));
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value, slide: 1, toggler: false, subfolder: "" });
  }

  formatDate(date_str) {
    const date = new Date(date_str);
    return `${date.getFullYear()}.${String(date.getMonth()).padStart(
      2,
      "0"
    )}.${date.getDate()}`;
  }

  changeSort = () => this.setState({ date_sort: !this.state.date_sort });

  changeSubfolder = (subfolder) => {
    this.setState({
      subfolder: this.state.subfolder === subfolder ? "" : subfolder,
      slide: 1,
      toggler: false,
    });
  };

  render() {
    const {
      editions,
      edition,
      authors,
      author,
      attachments,
      subfolder,
      date_sort,
      loading,
      serverError,
      toggler,
      slide,
    } = this.state;

    const {
      handleChange,
      formatDate,
      changeSubfolder,
      openLightboxOnSlide,
    } = this;

    const filteredFiles = attachments?.filter(
      ({ path, link, authors, editions }) =>
        (!!path || !!link) &&
        (authors.includes(author) || author === "") &&
        (editions.includes(edition) || edition === "")
    );

    const types = new Set(filteredFiles?.map((file) => file.subfolder));
    const files = filteredFiles
      ?.filter(({ subfolder: folder }) =>
        subfolder ? folder === subfolder : true
      )
      .sort((a, b) =>
        date_sort
          ? a.created_at - b.created_at
          : a.filename.localeCompare(b.filename)
      );

    const previewable = ["PHOTO", "ANIMATION", "VIDEO"].includes(subfolder);

    return (
      <div className="Files">
        <div className="Files__topbar">
          <div className="Files__header">
            <FormattedMessage id={"app.files"} />
          </div>
          <div className="Files__filter">
            <form>
              <label htmlFor="edition">
                <FormattedMessage id="app.edition" />
                <input
                  name="edition"
                  id="edition"
                  list="edition-list"
                  value={edition}
                  onChange={handleChange}
                />
                <datalist id="edition-list">
                  {editions.map((edition) => (
                    <option key={edition.id} value={edition.year} />
                  ))}
                </datalist>
              </label>
              <label htmlFor="author">
                <FormattedMessage id="app.author" />
                <input
                  name="author"
                  id="author"
                  list="author-list"
                  value={author}
                  onChange={handleChange}
                />
                <datalist id="author-list">
                  {authors.map((author) => (
                    <option
                      key={author.id}
                      value={`${author.surname} ${author.name}`}
                    />
                  ))}
                </datalist>
              </label>
            </form>
          </div>
        </div>

        <div className="Files__folders">
          {Object.keys(SUBFOLDERS)
            .filter((folder) => types.has(folder))
            .map((type) => (
              <figure
                key={type}
                className="clickable"
                style={
                  type === subfolder
                    ? {
                        padding: "10px",
                        backgroundColor: "#dddddd",
                        borderRadius: "7px",
                        boxShadow: "#cccccccc 0px 5px 15px",
                      }
                    : { padding: "10px" }
                }
                onClick={() => changeSubfolder(type)}
              >
                {SUBFOLDERS[type]}
                <figcaption className="alignCenter">
                  <FormattedMessage id={`subfolders.${type}`} />
                </figcaption>
              </figure>
            ))}
        </div>

        {loading ? (
          <Loader />
        ) : serverError ? (
          <ServerError />
        ) : files.length > 0 ? (
          subfolder !== "" ? (
            <div className="Files__items">
              <h2>{<FormattedMessage id={`subfolders.${subfolder}`} />}</h2>
              <div className="files">
                {files.map(
                  ({ link, filename, created_at, path, ...rest }, index) => (
                    <figure key={filename}>
                      <h3 className="alignCenter">{filename}</h3>
                      {previewable ? (
                        <img
                          alt={filename}
                          src={
                            path ||
                            link.replace(
                              /(https?:\/\/)?(www\.)?(youtube\.com\/(v\/|watch\?v=)|youtu\.be\/)(\w+)(\?|&).+/g,
                              "https://img.youtube.com/vi/$5/maxresdefault.jpg"
                            )
                          }
                          className="clickable"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = SUBFOLDERSSRC[subfolder];
                          }}
                          onClick={() => openLightboxOnSlide(index + 1)}
                        />
                      ) : (
                        <a
                          href={path || link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img alt={filename} src={SUBFOLDERSSRC[subfolder]} />
                        </a>
                      )}

                      <figcaption className="alignCenter">
                        <FormattedMessage id="app.created_at" />:{" "}
                        {formatDate(created_at)}
                      </figcaption>
                      {rest.editions?.length > 0 ? (
                        <figcaption className="alignCenter">
                          <FormattedMessage id="app.editions" />:{" "}
                          {rest.editions.sort().join(", ")}
                        </figcaption>
                      ) : null}
                      {rest.authors?.length > 0 ? (
                        <figcaption className="alignCenter">
                          <FormattedMessage id="app.authors" />:{" "}
                          {rest.authors.sort().join(", ")}
                        </figcaption>
                      ) : null}
                    </figure>
                  )
                )}
              </div>
              <FsLightbox
                toggler={toggler}
                slide={slide}
                sources={files.map((file) => file.path || file.link)}
                key={"lightbox" + subfolder}
              />
            </div>
          ) : null
        ) : (
          <div className="Files__items">
            <h3>
              <strong>Brak plików</strong>
            </h3>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(Files);
