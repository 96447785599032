import axios from 'axios';

export function fetchAuthors(namesOnly = false, author, edition, letter) {
  const params = {};
  if(author) params.search = author;
  if(edition) params.edition = edition;
  if(letter && letter !== '*') params.letter = letter;
  // console.log('params', params);
  return axios
    .get('/api/authors/', { params })
    .then((response) => {
      const results = response.data.results;
      if(namesOnly)
        return results.map((author) => {
          const { id, name, surname } = author;
          return {id, name, surname};
        });
      else return results;
    });
}