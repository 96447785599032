import Location from 'react-app-location';
import * as Yup from 'yup';

const idValidator = Yup.number().integer().positive().required();

const Locations = {
  Home: new Location('/'),
  Editions: new Location('/edycje'),
  Edition: new Location('/edycje/:id', { id: idValidator }),
  Authors: new Location('/autorzy'),
  Author: new Location('/autorzy/:id', { id: idValidator }),
  Artworks: new Location('/prace'),
  Artwork: new Location('/prace/:id', { id: idValidator }),
  Laureates: new Location('/laureaci'),
  CuratorExhibitions: new Location('/wystawa-kuratorska'),
  Contact: new Location('/kontakt'),
  Files: new Location('/pliki', {}, { author: Yup.string(), edition: Yup.number().integer().positive() }),
  History: new Location('/historia')
};

export default Locations;