import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Loader from '../../components/Loader';
import ServerError from '../../components/ServerError';

import Locations from '../../constants/Locations';

import { fetchEditions } from '../../requests';

import './Editions.scss';

class Editions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editions: [],
      loading: true
    };
  }

  fetchData() {
    const { isCuratorExhibition } = this.props;
    fetchEditions(false, isCuratorExhibition)
      .then(editions => this.setState({ editions, loading: false }))
      .catch(error => this.setState({ serverError: true, loading: false }));

  }

  componentDidMount() {
    this.fetchData();
  };

  componentDidUpdate(prevProps) {
    if(this.props.isCuratorExhibition !== prevProps.isCuratorExhibition) {
      this.setState({ loading: true });
      this.fetchData();
    }
  }

  render() {
    const { editions, loading, serverError } = this.state;
    const { isCuratorExhibition } = this.props;
    return (
      <div className='Editions'>
        <div className='Editions__header'><FormattedMessage id={ isCuratorExhibition ? 'app.curatorExhibitionsHeader' : 'app.editionsHeader' }/></div>
        <div className='Editions__items'>
          {
            loading ? <Loader/> : (
              serverError ? <ServerError/> : (
                editions.map((edition) => (
                  <div className='Edition-item' key={edition.id}>
                    <Link to={Locations.Edition.toUrl({id: edition.id})}>
                      <div className='Edition-item__photo'><img src={edition.mainPhotoPath} alt=''/></div>
                      <div className='Edition-item__year'>{edition.year}</div>
                    </Link>
                  </div>
                ))
              )
            )
          }
        </div>
      </div>
    );
  }
}

export default Editions;