import React, { Component } from 'react';
import FsLightbox from 'fslightbox-react';

import './Gallery.scss';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      slide: 1,
    };

    this.openLightboxOnSlide = this.openLightboxOnSlide.bind(this);
  }

  openLightboxOnSlide(slideNumber) {
    this.setState((currentState) => ({
      toggler: !currentState.toggler,
      slide: slideNumber
    }));
  }

  render() {
    const { photos } = this.props;
    const { toggler, slide } = this.state;
    const { openLightboxOnSlide } = this;

    return (
      <div className='Gallery'>
        {
          photos.map((photo, index) => (
            <button className='Gallery__photo' type='button' key={photo.id} onClick={() => openLightboxOnSlide(index + 1) }>
              <figure>
                <img src={photo.thumbnail} alt=''/>
                {photo.description && <figcaption>{photo.description}</figcaption>}
              </figure>
            </button>
          ))
        }
        <FsLightbox toggler={toggler} slide={slide} sources={photos.map(photo => photo.path)}/>
      </div>
    )
  }
}

export default Gallery;