import React from 'react';
import { FormattedMessage } from 'react-intl';

import rusztowanie from '../../images/rusztowanie.jpg';

import './Home.scss';

const Home = () => (
  <div className='Home'>
    <div className='Home__photo'>
      <img src={rusztowanie} alt=''/>
      <div className='Home__description'>
        <div className='Home__description-header'><FormattedMessage id='app.homeHeader'/></div>
        <div><FormattedMessage id='app.homeDescription'/></div>
      </div>
    </div>
  </div>
);

export default Home;