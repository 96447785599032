import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Locations from '../../constants/Locations';

import mainLogo from '../../images/main-logo.png';

import './Header.scss';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNav: false
    };

    this.switchNav = this.switchNav.bind(this);
  }

  switchNav() {
    this.setState((currentState) => ({showNav: !currentState.showNav}));
  }

  render() {
    const { showNav } = this.state;
    const { switchNav } = this;

    return (
      <div className='Header'>
        <div className='Header__logo'>
          <Link to={Locations.Home.toUrl()}>
            <img src={mainLogo} alt=''/>
          </Link>
        </div>
        <h1 className='Header__text'>
          <div><FormattedMessage id='app.mainTitle.part1'/></div>
          <div className='Header__text--strong'>
            <FormattedMessage id='app.mainTitle.part2'/>
          </div>
        </h1>
        <div className='Header__nav-switch' onClick={switchNav}>
          <div className={classNames('Header__nav-switch-icon', {'Header__nav-switch-icon--opened': showNav})}>
            <div/><div/><div/>
          </div>
        </div>
        <nav className={classNames('Header__nav', {'Header__nav--show': showNav})}>
          <Link to={Locations.Editions.toUrl()} onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.editions'/>
            </div>
          </Link>
          <Link to={Locations.Authors.toUrl()} onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.authors'/>
            </div>
          </Link>
          <Link to={Locations.Artworks.toUrl()} onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.artworks'/>
            </div>
          </Link>
          <Link to={Locations.Laureates.toUrl()} onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.laureates'/>
            </div>
          </Link>
          <Link to={Locations.CuratorExhibitions.toUrl()} onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.curatorExhibitions'/>
            </div>
          </Link>
          <Link to='/historia' onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.history'/>
            </div>
          </Link>
          <Link to={Locations.Files.toUrl()} onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.files'/>
            </div>
          </Link>
          <Link to={Locations.Contact.toUrl()} onClick={switchNav}>
            <div className='Header__link'>
              <FormattedMessage id='app.contact'/>
            </div>
          </Link>
        </nav>
      </div>
    );
  }
}

export default Header;