import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FsLightbox from 'fslightbox-react';

import Loader from '../../components/Loader';
import ServerError from '../../components/ServerError';

import { fetchAuthor } from '../../requests';

import Locations from '../../constants/Locations';

import './Author.scss';

class Author extends Component {

  constructor(props) {
    super(props);

    this.state = {
      author: {},
      loading: true,
      toggler: false,
      slide: 1,
    };

    this.openLightboxOnSlide = this.openLightboxOnSlide.bind(this);
  }

  openLightboxOnSlide(slideNumber) {
    this.setState((currentState) => ({
      toggler: !currentState.toggler,
      slide: slideNumber
    }));
  }

  componentDidMount() {
    const { id } = this.props;
    fetchAuthor(id)
      .then(author => this.setState({ author, loading: false }))
      .catch(error => this.setState({ serverError: true, loading: false }));
  };

  render() {
    const { author, loading, serverError, toggler, slide } = this.state;
    const { name, surname, description, artworks, path } = author;
    const { openLightboxOnSlide } = this;

    const photos = artworks && artworks.reduce((photos, artwork) => {
      const { name: description, edition, technology, sizes } = artwork;
      const artworkPhotos = artwork.photos.filter((photo) => !!photo.path)
        .map(photo => ({ ...photo, description, edition, technology, sizes }));
      return [...photos, ...artworkPhotos];
    }, []);

    return (
      loading ? <Loader/> : (
        serverError ? <ServerError/> : (
          <div className='Author'>
            <div className='Author__topbar'>
              <div className='Author__header'>{`${name} ${surname}`}</div>
              <div className='link'>
                <Link to={Locations.Files.toUrl({ author: `${surname} ${name}` })}><FormattedMessage id='app.filelink'/></Link>
              </div>
            </div>
              <div className='Author__photo'>
                <button type='button' onClick={() => openLightboxOnSlide(1)}>
                  <figure>
                    <img src={path} alt=''/>
                  </figure>
                </button>
              </div>
              <div className='Author__description' dangerouslySetInnerHTML={{__html: description}}/>
              <div className='Author__gallery'>
                <div className='Gallery'>
                  {
                    photos.map((photo, index) => (
                      <button className='Gallery__photo' type='button' key={photo.id} onClick={() => openLightboxOnSlide(index + 2) }>
                        <figure>
                          <img src={photo.thumbnail} alt=''/>
                          {photo.description && <figcaption>
                            <span dangerouslySetInnerHTML={{__html: photo.description}}></span>
                            { photo.edition && `, edycja: ${photo.edition}` }
                            , rozmiar: { photo.sizes }
                            , technika: { photo.technology }
                          </figcaption>
                          }
                        </figure>
                      </button>
                    ))
                  }
                  <FsLightbox toggler={toggler} slide={slide} sources={[path, ...photos.map(photo => photo.path)]}/>
                </div>
              </div>
          </div>
        )
      )
    )
  }
}

export default Author;
