import React from "react";

export const SUBFOLDERSSRC = {
  TEXT:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAACiklEQVR4nO2bMWgUQRSGP4MEBb0mIBqChYYYhFiIJhai2KiYQohl7BItBcuARTq1tBRsRJJCMFjIRRAtRBsNUYw2kk4MMWIiRBSTw1jMnnk5smZ2b/beZnkfDLy5nXnvn3+4KeZuwTCMhJSAa8AE8ANYSdmWgJsxNZqjZzMJc/4GXgBHwy13LT0pRP2vLcbUuVFn3nlgd6hFV+kEvtcprHa3rsfU+hwg/6VA6/7HM5H8GzAA7AldJEIuJAnDYt6wz4StnokPAqeiuAKcBV4nFJdLmjzHHRfxOAVZPPgbsEvE77MQooWvAfKrspSFEC18DSgsZoC2AG3MAG0B2pgB2gK0MQO0BSSkDXgE/AIeRv262EwG9AFvgV5gG3Ae+ABcBrakTboZDNgO3AIeAC01z0rAbeAxsDdN8rwasCzid8AV0f8IXABeis9OA1PAmZgcseTVgEkRt4v4DnAYGANOAFeBn9GzEnBMjH0TUlDim5Y6OQLMiZpfcWfAerQDz1l7i3Qfz831vRFqNBNAB3Au6pdx95HrMQ2cxN1SdeEOxjKe12l5NQDcgkc9x67gbqrGkxbJ6xnQMMwAbQHa5PkMSEMT0B/FI8CfjSYUzYCLwF3Rv7fRhKJ9BfaJeL/PhKIZkBgzQFuANmaAtgBtzABtAdqYAdoCtDEDtAVoYwZoC9DGDNAWoI0ZoC1AGzNAW4A2ZoC2AG18DaiIuDkLIYGQ2iqxowS+BnwRcZe3nMZzSMSzIRN3svrb+zLQHTJ5IHpwu17V2RG6wFORfB4YBFpDF0lBK+6PUgus6nuSRaEDNUXy2hbIYPerdBPmja6s2icyfGewyk5gCHiFe+9Pe9GLkZYhYEeG6zaMIvIXmdozmE82crcAAAAASUVORK5CYII=",
  PHOTO:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAFDUlEQVR4nO3ba6hVRRQH8J8PyrKHFZWaX8wysyIzSoqiSLOIHlB+MaIPZSkRWCSEqSDRA0IkIyIygsqiSKgoKEgjokizx4eCymf0EILsWop2zawP6xzunHPPY597z75nX7l/2DD7zJpZM/+9ZtY81mEIQ2gVx2EpvsBe/DeAz178gHcwH2Nz7msvzMDOPjQ8r2c/VuCkPDtdxhTsHsDOtfL8jIvy63rgw0ThLtyJcXkrLWF5ons1FmCjShL2Y3ZeDTg7UfSPAWC7CikBy5Pf5+D3JO8PnNFKxcMzyl2epN/DplaU5Ii1uAS/lt5PwIutVJCVgFOS9LetKGgTDibpI6rytuBGHCi9X4qbslaclYCRSfpAXan88FuSPq9G/ld4Mnm/L2vFWQnoND5J0tfi4hoyq/RYymViODTFYCHgO+GFCGt8H/MwPpHZqWduGimGQlOMbC5SGNyDDRgjvu7qJvKnZal0sFgAsQS+RnzpLBiTRWgwEQCfixXpQ8Lc99aR24Y3s1Q4mIZAGXvweOnpNwabBbQdQwR0ugGdxuFGwGRsF5PgmVkKFHUSHI1ZmCR2eduwDvualJuDiUm66URZNAKGYRGW4PiqvN14BCsFKbWQbpSOzKKwSENgOF7BE3p3nljYrMAaQVRbUCQLeABzk/dtWF9KzxTDAW7Fl8IS+o2iWMBoYfZlPI9zxMnv/FL6hSR/GY5uh+KiEDBTj9lvxb3oTvK7xWZoe+l9DK5qh+KiEDA5Sa9T2fkyukt5tcr0GUUhIJ3VG7UpnfzqeYKWUBQCNifpmWq7sFFibVCrTJ9RFALWCz9PzPbPqCRhVOm38iKnS88JUb9QFDe4TyxyVpTe78CVYswPE19+YiL/sLgI6TeKQgDh16cLPw+n4+4acmvEAWhbUJQhQExqt4kF0e4a+V24H7dr0wRIsSyA6NhKPCsmw/I11xYxT7TF7FNkJSC9DKnlo9uNfSIGIHdkHQJrxdp8Wyl92CCrBWzW4q3rYEGRJsGOYIiATjegBsapvI7PFQNBwHDh3+dqfpIzW8T77BDXYLljIAhYhZfxquaHlBdihDjseF2E5gxqLNQ7omteA/lThQWUZbdqLQSuXixRR3CdCFioJuAArm5Q7gKVAZgf6x0WUw+FIWC6uMQsN2ajuNktv3dhaoPyt+BQIv9cRr2FIGA8fkoaskOY9lj8WOP3elim0nIWZtDdcQKOwddJI/5UGdQ0VXz9cv4m9U93h4mtb1n2IK5vor+jBIzA25qP9WtEsGVZbq363mgUPktk/8K5DdrQUQKeUmmyCxrIzquSbeQex6ocUttxch3ZjhFQ7e4ezVCmfMeXhbBpsnmGjhBwg0p395psd3fD8ZbKITOrgfzN+DeRf7qGzIATUO3uPhXjNitGiz9eZHWPSxLZv/UmekAJmCCClNNVW72x2Qj13GYtDBMhsXv0nCCnGDACjlXp7nbhrH7Ud76Y4cv1bcBRfajnsaSOpf1oT0OMwLuJom5c0YZ6q5fOb2h9s5a2q9Geo19I3d0hcUzdLtyV1N3MPVZjhkoC23J5Wo1qd5fHOFtVpaPZlxwvLlDSFeYHObSrl7t7SRtDVRKMEEfizdxjOSKserfZJYevP0Wlu/tI9i1qX1C9p+jS+1R6kd6dz+3fYw8mSr7HiXkoqcIE/JLoXVyVP1m43j1iq71YEJcLJuEb8deUSU1k24lpwsx3CCscwhCG0F78D7OH3dHTOpEKAAAAAElFTkSuQmCC",
  VIDEO:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAC/klEQVR4nO2byWsUQRSHP2TUi4qjIBrwEJckggoeTBBR8KQRFxD/A0VB3K4RD7nl4CUuN0/+AwoaHFH05EFUEDQQAxrFuOISYUaEOKCH100qySzVZqpezVAfFFM9XV3v1a9rqqpf10AkEsnIEuA88BQoAX89phIwAgwBx4GVjts6ix7g43847ir9Bi4Ay1w2OqUL+OmxcVnSO2CLu6YLDwyD34EjwCrXRhP6DdtXke7/iOki/AJ2uXJgg2HoD7DVlaEqmAL0G98fAr4a574Ba7NUPM+y3A4jXwCeZDHikOvANuB9crwcuJalAlsBVhj54SwGGkTZyC+Yce4VcACYTI63AwdtK7YVIGfkJ6uWcscXI7+pwvlnwKBxfNq2YlsBtHlo5PcA3RXKDDLVU3ZiOTU2iwAjyCwE0hvvAEeBNqPMJ6bGphwyNtQlV79IMJxApr6lQB6ZDmvRVuc80Dw9AGAU2I2sRG3I2xRqJgEAHiMr0nNIdy9VKfcauGFTYTP9BFKKwECS5kyz9YCGEwXQdkCbVhOgAxhDBsH1Nhe0mgCHgXZgTZKvS6sJYD4oLbS5oNUEyEwUQNsBbaIAnu21A6eQGGMQ+BagAFwCngOX8RTPr4VvATqTzxxwEonnnUHxoUx7DMgjoawXQK+GA9oCpHQBt4FbZIzrz5VQBEjZh8T/LiIvYZ0TmgAA85Gw9jAyazglRAFSVgP7XRsJWYBx4KZrIyEKMImsFTYCb10bCy0oOoSsC8Z8GQylB7wE9iK/eW+NB30BJoCzyAvPgoYDvgUYTT7LwBVgHTLnl6te4RjfY0Av8i7/LrLgUce3AG+QOx4M2mOAOlEAbQe0iQJoO6BNFEDbAW2iANoOaBMF0HZAG1sBam1WDgnTN6snTFsB6m1WDoXNRv5zIyvuYvofJiptVtamB7nrqZ8djTZw36j8B7M3K2vRBhxDokupf/dcGOqcYSTUNIGDu5/SDXwIoJHV0jge/s+0GOhDNi4XlRpqpmLiSx+wyGG7I5FW5B+cwk5Zm8N0vQAAAABJRU5ErkJggg==",
  ANIMATION:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAC/klEQVR4nO2byWsUQRSHP2TUi4qjIBrwEJckggoeTBBR8KQRFxD/A0VB3K4RD7nl4CUuN0/+AwoaHFH05EFUEDQQAxrFuOISYUaEOKCH100qySzVZqpezVAfFFM9XV3v1a9rqqpf10AkEsnIEuA88BQoAX89phIwAgwBx4GVjts6ix7g43847ir9Bi4Ay1w2OqUL+OmxcVnSO2CLu6YLDwyD34EjwCrXRhP6DdtXke7/iOki/AJ2uXJgg2HoD7DVlaEqmAL0G98fAr4a574Ba7NUPM+y3A4jXwCeZDHikOvANuB9crwcuJalAlsBVhj54SwGGkTZyC+Yce4VcACYTI63AwdtK7YVIGfkJ6uWcscXI7+pwvlnwKBxfNq2YlsBtHlo5PcA3RXKDDLVU3ZiOTU2iwAjyCwE0hvvAEeBNqPMJ6bGphwyNtQlV79IMJxApr6lQB6ZDmvRVuc80Dw9AGAU2I2sRG3I2xRqJgEAHiMr0nNIdy9VKfcauGFTYTP9BFKKwECS5kyz9YCGEwXQdkCbVhOgAxhDBsH1Nhe0mgCHgXZgTZKvS6sJYD4oLbS5oNUEyEwUQNsBbaIAnu21A6eQGGMQ+BagAFwCngOX8RTPr4VvATqTzxxwEonnnUHxoUx7DMgjoawXQK+GA9oCpHQBt4FbZIzrz5VQBEjZh8T/LiIvYZ0TmgAA85Gw9jAyazglRAFSVgP7XRsJWYBx4KZrIyEKMImsFTYCb10bCy0oOoSsC8Z8GQylB7wE9iK/eW+NB30BJoCzyAvPgoYDvgUYTT7LwBVgHTLnl6te4RjfY0Av8i7/LrLgUce3AG+QOx4M2mOAOlEAbQe0iQJoO6BNFEDbAW2iANoOaBMF0HZAG1sBam1WDgnTN6snTFsB6m1WDoXNRv5zIyvuYvofJiptVtamB7nrqZ8djTZw36j8B7M3K2vRBhxDokupf/dcGOqcYSTUNIGDu5/SDXwIoJHV0jge/s+0GOhDNi4XlRpqpmLiSx+wyGG7I5FW5B+cwk5Zm8N0vQAAAABJRU5ErkJggg==",
  OTHER:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAADLklEQVR4nO2bv2sUQRTHPwmHFuqJIP4IKJhESfxZ+DOClYVWUcTOYKOgCLYWYpG/QLQWRNEIgo1aaBMrLfyBhR5ITGIh5uBEJZADNTlyFrPLvZy33OzezM79mA8szN69efPmu7M78+5mwePxxCQLXAPeAUWgnOJRBCaAp8AFYIPlvv7HQSCfIHBbx2/gOrDWZqdDBoDZFDsX58gDh+11XfFCNPgTOAdstN1owKho+xZwEXjNUhH+AidtBTAoGloA9ttqKAIpwKj4/DTwQ3w3B+yK47hb0+6IKD8D3sZpxCKPgCFgJjhfCdxHv1/ahutEOafr3CAlUV5W9d0kMAzMB+e7gRFdx7oCZER5PtLKHgVRrjXE3wM3xPllXcfaQ8UxL0X5OHCghs1NKiNlL7BJx3GrCPAJNQuBGo3PgfNAj7DJU3k2daE5LaYhwDbgC/Hn9mlgq/BzCbUOAViDmg5nquoMCfvNOsGlIcAwsCVBvd6gbsgEcAx1pXXI6hilIcAT1NWMy3RQV/IGtSK9ihruxYi6OeB2gjYjiVqItDyt8hC0RscLkKlv0lJ0A2eC8hiwWK9CuwkwAtwV5/fqVWi3W6BXlPt0KrSbALHxArgOwDVeANcBuMYLYNhf0tQ3aYrcMKYFSJr66lKdIjeMaQGSpr661EqRG8L0Uvgz0G/Yp1X8Q9B1AK7peAFMPwPCfLynnmFC8mjm+bqYFqA6H7dF3TxfF9O3QNmwP+ttmB4BY6gAdxr2G5IDHph0aFqARdTf0y1Dx88CXgDXAbjGtADrgY8kT3cXqD2N9gNTwWE01zAtwFEamwEywFlgVdXnJ1A/c/fR5OnwOGoEJKUE3EHt9pI8Rl39yaBsDNPTYAG1Sck0Uxj+JSjEPwRdB+AaL4DrAFzjBXAdgGu8AJp2crNyM+8qkRupS5FWAl0Bvovydu1w0kcuwwuRVgnYQSVh+YOlVVmD7EHtZA/jHDDdwCvh/APNI0IW9arMNyrxjetW7orR0D7UtvXlwXmZyuZll6xm6a08CxxC7S02zinULeD6LbGo4yspvM80CDwEfjnsqDzmUG+QXQFWWOy3x9OO/ANK12ifad5kUAAAAABJRU5ErkJggg==",
};

export const SUBFOLDERS = {
  TEXT: (
    <img
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAACiklEQVR4nO2bMWgUQRSGP4MEBb0mIBqChYYYhFiIJhai2KiYQohl7BItBcuARTq1tBRsRJJCMFjIRRAtRBsNUYw2kk4MMWIiRBSTw1jMnnk5smZ2b/beZnkfDLy5nXnvn3+4KeZuwTCMhJSAa8AE8ANYSdmWgJsxNZqjZzMJc/4GXgBHwy13LT0pRP2vLcbUuVFn3nlgd6hFV+kEvtcprHa3rsfU+hwg/6VA6/7HM5H8GzAA7AldJEIuJAnDYt6wz4StnokPAqeiuAKcBV4nFJdLmjzHHRfxOAVZPPgbsEvE77MQooWvAfKrspSFEC18DSgsZoC2AG3MAG0B2pgB2gK0MQO0BSSkDXgE/AIeRv262EwG9AFvgV5gG3Ae+ABcBrakTboZDNgO3AIeAC01z0rAbeAxsDdN8rwasCzid8AV0f8IXABeis9OA1PAmZgcseTVgEkRt4v4DnAYGANOAFeBn9GzEnBMjH0TUlDim5Y6OQLMiZpfcWfAerQDz1l7i3Qfz831vRFqNBNAB3Au6pdx95HrMQ2cxN1SdeEOxjKe12l5NQDcgkc9x67gbqrGkxbJ6xnQMMwAbQHa5PkMSEMT0B/FI8CfjSYUzYCLwF3Rv7fRhKJ9BfaJeL/PhKIZkBgzQFuANmaAtgBtzABtAdqYAdoCtDEDtAVoYwZoC9DGDNAWoI0ZoC1AGzNAW4A2ZoC2AG18DaiIuDkLIYGQ2iqxowS+BnwRcZe3nMZzSMSzIRN3svrb+zLQHTJ5IHpwu17V2RG6wFORfB4YBFpDF0lBK+6PUgus6nuSRaEDNUXy2hbIYPerdBPmja6s2icyfGewyk5gCHiFe+9Pe9GLkZYhYEeG6zaMIvIXmdozmE82crcAAAAASUVORK5CYII="
    />
  ),
  PHOTO: (
    <img
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAFDUlEQVR4nO3ba6hVRRQH8J8PyrKHFZWaX8wysyIzSoqiSLOIHlB+MaIPZSkRWCSEqSDRA0IkIyIygsqiSKgoKEgjokizx4eCymf0EILsWop2zawP6xzunHPPY597z75nX7l/2DD7zJpZM/+9ZtY81mEIQ2gVx2EpvsBe/DeAz178gHcwH2Nz7msvzMDOPjQ8r2c/VuCkPDtdxhTsHsDOtfL8jIvy63rgw0ThLtyJcXkrLWF5ons1FmCjShL2Y3ZeDTg7UfSPAWC7CikBy5Pf5+D3JO8PnNFKxcMzyl2epN/DplaU5Ii1uAS/lt5PwIutVJCVgFOS9LetKGgTDibpI6rytuBGHCi9X4qbslaclYCRSfpAXan88FuSPq9G/ld4Mnm/L2vFWQnoND5J0tfi4hoyq/RYymViODTFYCHgO+GFCGt8H/MwPpHZqWduGimGQlOMbC5SGNyDDRgjvu7qJvKnZal0sFgAsQS+RnzpLBiTRWgwEQCfixXpQ8Lc99aR24Y3s1Q4mIZAGXvweOnpNwabBbQdQwR0ugGdxuFGwGRsF5PgmVkKFHUSHI1ZmCR2eduwDvualJuDiUm66URZNAKGYRGW4PiqvN14BCsFKbWQbpSOzKKwSENgOF7BE3p3nljYrMAaQVRbUCQLeABzk/dtWF9KzxTDAW7Fl8IS+o2iWMBoYfZlPI9zxMnv/FL6hSR/GY5uh+KiEDBTj9lvxb3oTvK7xWZoe+l9DK5qh+KiEDA5Sa9T2fkyukt5tcr0GUUhIJ3VG7UpnfzqeYKWUBQCNifpmWq7sFFibVCrTJ9RFALWCz9PzPbPqCRhVOm38iKnS88JUb9QFDe4TyxyVpTe78CVYswPE19+YiL/sLgI6TeKQgDh16cLPw+n4+4acmvEAWhbUJQhQExqt4kF0e4a+V24H7dr0wRIsSyA6NhKPCsmw/I11xYxT7TF7FNkJSC9DKnlo9uNfSIGIHdkHQJrxdp8Wyl92CCrBWzW4q3rYEGRJsGOYIiATjegBsapvI7PFQNBwHDh3+dqfpIzW8T77BDXYLljIAhYhZfxquaHlBdihDjseF2E5gxqLNQ7omteA/lThQWUZbdqLQSuXixRR3CdCFioJuAArm5Q7gKVAZgf6x0WUw+FIWC6uMQsN2ajuNktv3dhaoPyt+BQIv9cRr2FIGA8fkoaskOY9lj8WOP3elim0nIWZtDdcQKOwddJI/5UGdQ0VXz9cv4m9U93h4mtb1n2IK5vor+jBIzA25qP9WtEsGVZbq363mgUPktk/8K5DdrQUQKeUmmyCxrIzquSbeQex6ocUttxch3ZjhFQ7e4ezVCmfMeXhbBpsnmGjhBwg0p395psd3fD8ZbKITOrgfzN+DeRf7qGzIATUO3uPhXjNitGiz9eZHWPSxLZv/UmekAJmCCClNNVW72x2Qj13GYtDBMhsXv0nCCnGDACjlXp7nbhrH7Ud76Y4cv1bcBRfajnsaSOpf1oT0OMwLuJom5c0YZ6q5fOb2h9s5a2q9Geo19I3d0hcUzdLtyV1N3MPVZjhkoC23J5Wo1qd5fHOFtVpaPZlxwvLlDSFeYHObSrl7t7SRtDVRKMEEfizdxjOSKserfZJYevP0Wlu/tI9i1qX1C9p+jS+1R6kd6dz+3fYw8mSr7HiXkoqcIE/JLoXVyVP1m43j1iq71YEJcLJuEb8deUSU1k24lpwsx3CCscwhCG0F78D7OH3dHTOpEKAAAAAElFTkSuQmCC"
    />
  ),
  VIDEO: (
    <img
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAC/klEQVR4nO2byWsUQRSHP2TUi4qjIBrwEJckggoeTBBR8KQRFxD/A0VB3K4RD7nl4CUuN0/+AwoaHFH05EFUEDQQAxrFuOISYUaEOKCH100qySzVZqpezVAfFFM9XV3v1a9rqqpf10AkEsnIEuA88BQoAX89phIwAgwBx4GVjts6ix7g43847ir9Bi4Ay1w2OqUL+OmxcVnSO2CLu6YLDwyD34EjwCrXRhP6DdtXke7/iOki/AJ2uXJgg2HoD7DVlaEqmAL0G98fAr4a574Ba7NUPM+y3A4jXwCeZDHikOvANuB9crwcuJalAlsBVhj54SwGGkTZyC+Yce4VcACYTI63AwdtK7YVIGfkJ6uWcscXI7+pwvlnwKBxfNq2YlsBtHlo5PcA3RXKDDLVU3ZiOTU2iwAjyCwE0hvvAEeBNqPMJ6bGphwyNtQlV79IMJxApr6lQB6ZDmvRVuc80Dw9AGAU2I2sRG3I2xRqJgEAHiMr0nNIdy9VKfcauGFTYTP9BFKKwECS5kyz9YCGEwXQdkCbVhOgAxhDBsH1Nhe0mgCHgXZgTZKvS6sJYD4oLbS5oNUEyEwUQNsBbaIAnu21A6eQGGMQ+BagAFwCngOX8RTPr4VvATqTzxxwEonnnUHxoUx7DMgjoawXQK+GA9oCpHQBt4FbZIzrz5VQBEjZh8T/LiIvYZ0TmgAA85Gw9jAyazglRAFSVgP7XRsJWYBx4KZrIyEKMImsFTYCb10bCy0oOoSsC8Z8GQylB7wE9iK/eW+NB30BJoCzyAvPgoYDvgUYTT7LwBVgHTLnl6te4RjfY0Av8i7/LrLgUce3AG+QOx4M2mOAOlEAbQe0iQJoO6BNFEDbAW2iANoOaBMF0HZAG1sBam1WDgnTN6snTFsB6m1WDoXNRv5zIyvuYvofJiptVtamB7nrqZ8djTZw36j8B7M3K2vRBhxDokupf/dcGOqcYSTUNIGDu5/SDXwIoJHV0jge/s+0GOhDNi4XlRpqpmLiSx+wyGG7I5FW5B+cwk5Zm8N0vQAAAABJRU5ErkJggg=="
    />
  ),
  ANIMATION: (
    <img
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAC/klEQVR4nO2byWsUQRSHP2TUi4qjIBrwEJckggoeTBBR8KQRFxD/A0VB3K4RD7nl4CUuN0/+AwoaHFH05EFUEDQQAxrFuOISYUaEOKCH100qySzVZqpezVAfFFM9XV3v1a9rqqpf10AkEsnIEuA88BQoAX89phIwAgwBx4GVjts6ix7g43847ir9Bi4Ay1w2OqUL+OmxcVnSO2CLu6YLDwyD34EjwCrXRhP6DdtXke7/iOki/AJ2uXJgg2HoD7DVlaEqmAL0G98fAr4a574Ba7NUPM+y3A4jXwCeZDHikOvANuB9crwcuJalAlsBVhj54SwGGkTZyC+Yce4VcACYTI63AwdtK7YVIGfkJ6uWcscXI7+pwvlnwKBxfNq2YlsBtHlo5PcA3RXKDDLVU3ZiOTU2iwAjyCwE0hvvAEeBNqPMJ6bGphwyNtQlV79IMJxApr6lQB6ZDmvRVuc80Dw9AGAU2I2sRG3I2xRqJgEAHiMr0nNIdy9VKfcauGFTYTP9BFKKwECS5kyz9YCGEwXQdkCbVhOgAxhDBsH1Nhe0mgCHgXZgTZKvS6sJYD4oLbS5oNUEyEwUQNsBbaIAnu21A6eQGGMQ+BagAFwCngOX8RTPr4VvATqTzxxwEonnnUHxoUx7DMgjoawXQK+GA9oCpHQBt4FbZIzrz5VQBEjZh8T/LiIvYZ0TmgAA85Gw9jAyazglRAFSVgP7XRsJWYBx4KZrIyEKMImsFTYCb10bCy0oOoSsC8Z8GQylB7wE9iK/eW+NB30BJoCzyAvPgoYDvgUYTT7LwBVgHTLnl6te4RjfY0Av8i7/LrLgUce3AG+QOx4M2mOAOlEAbQe0iQJoO6BNFEDbAW2iANoOaBMF0HZAG1sBam1WDgnTN6snTFsB6m1WDoXNRv5zIyvuYvofJiptVtamB7nrqZ8djTZw36j8B7M3K2vRBhxDokupf/dcGOqcYSTUNIGDu5/SDXwIoJHV0jge/s+0GOhDNi4XlRpqpmLiSx+wyGG7I5FW5B+cwk5Zm8N0vQAAAABJRU5ErkJggg=="
    />
  ),
  OTHER: (
    <img
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAADLklEQVR4nO2bv2sUQRTHPwmHFuqJIP4IKJhESfxZ+DOClYVWUcTOYKOgCLYWYpG/QLQWRNEIgo1aaBMrLfyBhR5ITGIh5uBEJZADNTlyFrPLvZy33OzezM79mA8szN69efPmu7M78+5mwePxxCQLXAPeAUWgnOJRBCaAp8AFYIPlvv7HQSCfIHBbx2/gOrDWZqdDBoDZFDsX58gDh+11XfFCNPgTOAdstN1owKho+xZwEXjNUhH+AidtBTAoGloA9ttqKAIpwKj4/DTwQ3w3B+yK47hb0+6IKD8D3sZpxCKPgCFgJjhfCdxHv1/ahutEOafr3CAlUV5W9d0kMAzMB+e7gRFdx7oCZER5PtLKHgVRrjXE3wM3xPllXcfaQ8UxL0X5OHCghs1NKiNlL7BJx3GrCPAJNQuBGo3PgfNAj7DJU3k2daE5LaYhwDbgC/Hn9mlgq/BzCbUOAViDmg5nquoMCfvNOsGlIcAwsCVBvd6gbsgEcAx1pXXI6hilIcAT1NWMy3RQV/IGtSK9ihruxYi6OeB2gjYjiVqItDyt8hC0RscLkKlv0lJ0A2eC8hiwWK9CuwkwAtwV5/fqVWi3W6BXlPt0KrSbALHxArgOwDVeANcBuMYLYNhf0tQ3aYrcMKYFSJr66lKdIjeMaQGSpr661EqRG8L0Uvgz0G/Yp1X8Q9B1AK7peAFMPwPCfLynnmFC8mjm+bqYFqA6H7dF3TxfF9O3QNmwP+ttmB4BY6gAdxr2G5IDHph0aFqARdTf0y1Dx88CXgDXAbjGtADrgY8kT3cXqD2N9gNTwWE01zAtwFEamwEywFlgVdXnJ1A/c/fR5OnwOGoEJKUE3EHt9pI8Rl39yaBsDNPTYAG1Sck0Uxj+JSjEPwRdB+AaL4DrAFzjBXAdgGu8AJp2crNyM+8qkRupS5FWAl0Bvovydu1w0kcuwwuRVgnYQSVh+YOlVVmD7EHtZA/jHDDdwCvh/APNI0IW9arMNyrxjetW7orR0D7UtvXlwXmZyuZll6xm6a08CxxC7S02zinULeD6LbGo4yspvM80CDwEfjnsqDzmUG+QXQFWWOy3x9OO/ANK12ifad5kUAAAAABJRU5ErkJggg=="
    />
  ),
};
