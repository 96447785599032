import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { string, func } from 'prop-types';

import Locations from '../../constants/Locations';

import bwaLogo from '../../images/bwa-logo.png';

import './Footer.scss';

const Footer = ({ otherLang, switchLang }) => (
  <div className='Footer'>
    <div className='Footer-icons'>
      <a href='https://icons8.com' target='_blank' rel='noopener noreferrer'>
        <FormattedMessage id='app.iconsSource'/>
      </a>
    </div>
    <div className='Footer-nav'>
      <div className='Footer-nav__item'>
        <Link to={Locations.Home.toUrl()}>
          <div className='Footer-nav__logo'>
            <img src={bwaLogo} alt=''/>
            <span><FormattedMessage id='app.galleryName'/></span>
          </div>
        </Link>
      </div>
      <div className='Footer-nav__item Footer-nav__lang'>
        <button onClick={switchLang}>{otherLang}</button>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  otherLang: string.isRequired,
  switchLang: func.isRequired
};

export default Footer;