import axios from 'axios';

export function changeLanguage(lang) {
  return axios.post('/lang/', {language: lang});
}

export function getLanguage() {
  return axios
    .get('/lang/')
    .then(response => { return response.data.language });
}