import axios from 'axios';
import { DEFAULT_LIMIT } from '../constants/variables';

export function fetchArtworks(author, edition, page = 0, limit = DEFAULT_LIMIT) {
  const offset = limit * page;
  const params = { limit, offset };
  if(author) params.search = author;
  if(edition) params.edition = edition;
  return axios
    .get('/api/artworks/', { params })
    .then((response) => {
      const { results, count } = response.data;
      return ({
        artworks: results.map((artwork) => {
          const { id, name, description, author, edition, sizes, photos } = artwork;
          const authorName = author && `${author.name} ${author.surname}`;
          const photoPath = photos.length && photos[0].path;
          return { id, name, description, authorName, edition, sizes, photoPath };
        }),
        count
      });
    });
}
