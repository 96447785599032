import React from 'react';

import { FormattedMessage } from 'react-intl';

import './Loader.scss';

const Loader = () => (
  <div className='Loader'>
    <div className='lds-ellipsis'><div/><div/><div/><div/></div>
    <FormattedMessage id='app.loading'/>
  </div>
);

export default Loader;