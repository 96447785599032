import React from 'react';
import classNames from 'classnames';

import './Pagination.scss';

const alphabetArr = 'ABCĆDEFGHIJKLŁMNOPRSŚTUWVYZŻŹ'.split('');

const Pagination = ({ page, pageCount, changePage, alphabet }) => (
  <div className='Pagination'>
    {
      alphabet
        ? ['*', ...alphabetArr].map((letter) => <button className={classNames('Pagination__item', {'Pagination__item--selected': letter === page})} disabled={letter === page} key={letter} onClick={() => changePage(letter)}>{letter}</button>)
        : [...Array(Math.ceil(pageCount)).keys()].map((index) => <button className={classNames('Pagination__item', {'Pagination__item--selected': index === page})} disabled={index === page} key={index} onClick={() => changePage(index)}>{index + 1}</button>)
    }
  </div>
);

export default Pagination;