import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FsLightbox from 'fslightbox-react';

import Loader from '../../components/Loader';
import ServerError from '../../components/ServerError';
import QRCode from 'qrcode.react';

import { fetchEdition } from '../../requests';

import Locations from '../../constants/Locations';

import './Edition.scss';

class Edition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edition: {},
      descriptionExpanded: false,
      loading: true,
      serverError: false,
      toggler: false,
      slide: 1,
    };

    this.toggleExpansion = this.toggleExpansion.bind(this);
    this.processDescription = this.processDescription.bind(this);
    this.openLightboxOnSlide = this.openLightboxOnSlide.bind(this);
  };

  openLightboxOnSlide(slideNumber) {
    this.setState((currentState) => ({
      toggler: !currentState.toggler,
      slide: slideNumber
    }));
  };

  componentDidMount() {
    const { id } = this.props;
    fetchEdition(id)
      .then(edition => this.setState({ edition, loading: false }))
      .catch(error => this.setState({ serverError: true, loading: false }));
  };

  toggleExpansion() {
    this.setState(currentState => ({
      descriptionExpanded: !currentState.descriptionExpanded
    }));
  }

  processDescription(description, short_description) {
    const { descriptionExpanded } = this.state;
    if(descriptionExpanded || !short_description) {
      return description;
    } else {
      return short_description;
    }
  }

  render() {
    const { edition, descriptionExpanded, loading, serverError, toggler, slide } = this.state;
    const { toggleExpansion, processDescription, openLightboxOnSlide } = this;
    const { year, photos, description, short_description } = edition;

    const displayedDescription = processDescription(description, short_description);
    const cleanPhotos = photos?.filter((photo) => !!photo.path);

    return (
      loading ? <Loader/> : (
        serverError ? <ServerError/> : (
          <div className='Edition'>
            <div className='Edition__topbar'>
              <div className='Edition__header'><FormattedMessage id='app.editionHeader' values={{year}}/></div>
              <div className='link'>
                <Link to={Locations.Files.toUrl({ edition: year })}><FormattedMessage id='app.filelink'/></Link>
              </div>
            </div>
            <div className='Edition__photo'>
              <button type='button' onClick={() => openLightboxOnSlide(1)}>
                <figure>
                  <img src={cleanPhotos[0]?.thumbnail} alt=''/>
                </figure>
              </button>
            </div>
            <QRCode className='Edition__qrcode' value={window.location.href} size={128} />
            <div className='Edition__description' dangerouslySetInnerHTML={{__html: displayedDescription}}/>
            <button type='button' className='Edition__read-more' onClick={toggleExpansion}>
              {
                short_description && (
                  descriptionExpanded
                    ? <FormattedMessage id='app.readLess'/>
                    : <FormattedMessage id='app.readMore'/>
                )
              }
            </button>
            <div className='Author__gallery'>
              <div className='Gallery'>
                {
                  cleanPhotos.slice(1).map((photo, index) => (
                    <button className='Gallery__photo' type='button' key={photo.id} onClick={() => openLightboxOnSlide(index + 2) }>
                      <figure>
                        <img src={photo.thumbnail} alt=''/>
                        {photo.description && <figcaption dangerouslySetInnerHTML={{__html: photo.description}}></figcaption>}
                      </figure>
                    </button>
                  ))
                }
                <FsLightbox toggler={toggler} slide={slide} sources={cleanPhotos.map(photo => photo.path)}/>
              </div>
            </div>
          </div>
        )
      )
    );
  }
}

export default Edition;