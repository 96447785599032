import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Locations from '../../constants/Locations';
import Gallery from '../../components/Gallery';
import Loader from '../../components/Loader';

import { fetchArtwork } from '../../requests';

import './Artwork.scss';

class Artwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artwork: {},
      loading: true,
    }
  }

  componentDidMount() {
    const { id } = this.props;
    fetchArtwork(id).then(artwork => this.setState({ artwork, loading: false }));
  }

  render() {
    const { artwork, loading } = this.state;
    const { name, photos, author, edition, technology, create_year, sizes } = artwork;
    return (
      <div className='Artwork'>
        {
          loading ? <Loader/>
            : <>
              <div className='Artwork__header'>{name}</div>
              <div className='Artwork__data'>
                <div className='Artwork__data-bullet'>
                  <span className='Artwork__data-label'><FormattedMessage id='app.author'/></span>
                  {
                    author
                      ?
                        <Link to={Locations.Author.toUrl({id: author.id})}>
                          {`${author.name} ${author.surname}`}
                        </Link>
                      : "-"
                  }
                </div>
                <div className='Artwork__data-bullet'>
                  <span className='Artwork__data-label'><FormattedMessage id='app.edition'/></span>
                  { edition }
                </div>
                <div className='Artwork__data-bullet'>
                  <span className='Artwork__data-label'><FormattedMessage id='app.technique'/></span>
                  { technology }
                </div>
                <div className='Artwork__data-bullet'>
                  <span className='Artwork__data-label'><FormattedMessage id='app.dateOfCreation'/></span>
                  { create_year }
                </div>
                <div className='Artwork__data-bullet'>
                  <span className='Artwork__data-label'><FormattedMessage id='app.dimensions'/></span>
                  { sizes }
                </div>
              </div>
              <div className='Artwork__images'>
                <Gallery photos={photos.filter((element) => !!element.path)}/>
              </div>
            </>
        }

      </div>
    )
  }
}

export default Artwork;
