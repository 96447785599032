import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Loader from '../../components/Loader';
import ServerError from '../../components/ServerError';

import { fetchPage } from '../../requests';

import './History.scss';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      loading: true
    };
  }

  componentDidMount() {
    fetchPage('history').then((content) => this.setState({ content, loading: false }))
      .catch(error => this.setState({ serverError: true, loading: false }));
  };

  render() {
    const { content, loading, serverError } = this.state;
    return (
      <div className='History'>
        <div className='History__header'><FormattedMessage id='app.history'/></div>
        {
          loading ? <Loader/> : (
            serverError ? <ServerError/> : (
              <div className='History__content' dangerouslySetInnerHTML={{__html: content}}></div>
            )
          )
        }
      </div>
    );
  }
}

export default History;