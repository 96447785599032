import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import Loader from '../../components/Loader';
import ServerError from '../../components/ServerError';
import Pagination from '../../components/Pagination';

import Locations from '../../constants/Locations';

import { fetchAuthors, fetchEditions } from '../../requests';

import './Authors.scss';
import queryString from 'query-string';

const AUTHOR_DESCRIPTION_CHARS = 300;

class Authors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authors: [],
      author: '',
      authorsNames: [],
      editions: [],
      edition: '',
      loading: true,
      letter: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  // componentDidMount() {
  //   fetchAuthors()
  //     .then(authors => this.setState({ authors, loading: false }))
  //     .catch(error => this.setState({ serverError: true, loading: false }));
  // };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({ ...values });
    Promise.all([fetchAuthors(false, values.author, values.edition), fetchAuthors(true), fetchEditions(true)]).then((values) => {
      this.setState((prevState) => ({ authors: values[0], authorsNames: values[1], editions: [ {id: '', year: '----'}, ...values[2] ], loading: false }));
    }).catch(error => this.setState({ serverError: true, loading: false }));
  };

  componentDidUpdate(prevProps) {
    if(this.props.location.search !== prevProps.location.search) {
      const values = queryString.parse(this.props.location.search);
      this.setState({ loading: true });
      fetchAuthors(false, values.author, values.edition, values.letter).then((authors) => this.setState({ authors: authors, loading: false }));
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    event.preventDefault();
    const { author, edition } = this.state;
    const { history } = this.props;

    const query = {};
    if(author) query.author = author;
    if(edition) query.edition = edition;
    // if(edition) {
    //   const editionIndex = editions.map(edition => edition.year).indexOf(Number(edition));
    //   if(editionIndex < 0) {
    //     this.setState({authors: []});
    //     return;
    //   }
    //   else query.edition = editions[editionIndex].id;
    // }

    history.push(`/autorzy?${queryString.stringify(query)}`);
  }

  changePage(letter) {
    const { author, edition } = this.state;
    this.setState({loading: true, letter: letter});

    fetchAuthors(false, author, edition, letter).then((authors) => this.setState({ authors, loading: false }))
  }

  render() {
    const { authors, authorsNames, author, edition, editions, loading, serverError, letter } = this.state;
    const { intl } = this.props;
    const { handleChange, handleSubmit, changePage } = this;

    return (
      <div className='Authors'>
        <div className='Authors__topbar'>
          <div className='Authors__header'><FormattedMessage id='app.authorsHeader'/></div>
          <div className='Authors__filter'>
            <form onSubmit={handleSubmit}>
              <label htmlFor='edition'>
                <FormattedMessage id='app.edition'/>
                <input name='edition' id='edition' list='edition-list' value={edition} onChange={handleChange}/>
                <datalist id='edition-list'>
                  {
                    editions.map((edition) => <option key={edition.id} value={edition.year}/>)
                  }
                </datalist>
              </label>
              <label htmlFor='author'>
                <FormattedMessage id='app.author'/>
                <input name='author' id='author' list='author-list' value={author} onChange={handleChange}/>
                <datalist id='author-list'>
                  {
                    authorsNames.map((author) => <option key={author.id} value={`${author.surname} ${author.name}`}/>)
                  }
                </datalist>
              </label>
              <input type='submit' value={intl.formatMessage({id: 'app.filter'})}/>
            </form>
          </div>
        </div>

        {
          loading ? <Loader/> : (
            serverError ? <ServerError/> : (
              <Fragment>
                <Pagination page={letter} alphabet changePage={changePage}/>
                <div className='Authors__items'>
                  {
                    authors.map((author) => (
                      <Link to={Locations.Author.toUrl({id: author.id})} key={author.id} className='Author-item__wrapper'>
                        <div className='Author-item'>
                          <div className='Author-item__photo'><img src={author.path} alt=''/></div>
                          <div className='Author-item__data'>
                            <div className='Author-item__name'>{`${author.surname} ${author.name}`}</div>
                            <div>{author.info.slice(0, AUTHOR_DESCRIPTION_CHARS)}{author.info.length > AUTHOR_DESCRIPTION_CHARS && '...'}</div>
                          </div>
                        </div>
                      </Link>
                    ))
                  }
                </div>
              </Fragment>
            )
          )
        }
      </div>
    );
  }
}

export default injectIntl(Authors);