import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import classNames from 'classnames';

import Loader from '../../components/Loader';
import ServerError from '../../components/ServerError';
import Pagination from '../../components/Pagination';

import Locations from '../../constants/Locations';
import { DEFAULT_LIMIT } from '../../constants/variables';

import { fetchArtworks, fetchAuthors, fetchEditions } from '../../requests';

import './Artworks.scss';

class Artworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artworks: [],
      editions: [],
      authors: [],
      author: '',
      editionId: 0,
      editionYear: '',
      loading: true,
      page: 0,
      count: 0,
      limit: DEFAULT_LIMIT
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.setState({ ...params });
    Promise.all([fetchArtworks(params.author, params.editionId), fetchAuthors(true), fetchEditions(true)]).then((values) => {
      this.setState((prevState) => (
        {
          count: values[0].count,
          artworks: values[0].artworks,
          authors: values[1],
          editions: [
            {
              id: '',
              year: '----'
            },
            ...values[2]
          ],
          loading: false,
          editionId: prevState.editionId && values[2][values[2].map(edition => edition.id).indexOf(Number(prevState.editionId))].id,
          editionYear: prevState.editionId && values[2][values[2].map(edition => edition.id).indexOf(Number(prevState.editionId))].year
        }
      ));
    }).catch(error => this.setState({ serverError: true, loading: false }));
  };

  componentDidUpdate(prevProps) {
    if(this.props.location.search !== prevProps.location.search) {
      const values = queryString.parse(this.props.location.search);
      this.setState({ loading: true });
      fetchArtworks(values.author, values.editionId).then((artworks) => this.setState({ count: artworks.count, artworks: artworks.artworks, loading: false, editionId: values.editionId }));
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    event.preventDefault();
    const { author, editionYear, editions } = this.state;
    const { history } = this.props;

    const query = {};
    if(author) query.author = author;
    if(editionYear) {
      const editionIndex = editions.map(edition => edition.year).indexOf(Number(editionYear));
      if(editionIndex < 0) {
        this.setState({artworks: []});
        return;
      }
      else query.editionId = editions[editionIndex].id;
    }

    history.push(`/prace?${queryString.stringify(query)}`);
  }
  
  changePage(page) {
    const { author, editionId, limit } = this.state;
    this.setState({loading: true, page: page});
    fetchArtworks(author, editionId, page, limit).then((result) => this.setState({ artworks: result.artworks, loading: false }))
  }

  changeLimit(limit) {
    const { author, editionId } = this.state;
    this.setState({loading: true, limit: limit, page: 0});
    fetchArtworks(author, editionId, 0, limit).then((result) => this.setState({ artworks: result.artworks, loading: false }))
  }

  render() {
    const { artworks, authors, editions, author, editionYear, loading, page, count, limit, serverError } = this.state;
    const { intl } = this.props;
    const { handleChange, handleSubmit, changePage, changeLimit } = this;

    const artworksByYears = artworks && artworks.length && artworks.reduce((artworksByYears, artwork) => {
      if(artworksByYears[artwork.edition]) artworksByYears[artwork.edition].push(artwork);
      else artworksByYears[artwork.edition] = [artwork];
      return artworksByYears;
    }, {});

    const artworksSorted = Object.entries(artworksByYears).sort((a, b) => b[0] > a[0] ? 1 : -1);

    return (
      <div className='Artworks'>
        <div className='Artworks__topbar'>
          <div className='Artworks__header'><FormattedMessage id='app.artworksHeader'/></div>
          <div className='Artworks__filter'>
            <form onSubmit={handleSubmit}>
              <label htmlFor='editionYear'>
                <FormattedMessage id='app.edition'/>
                <input name='editionYear' id='editionYear' list='edition-list' value={editionYear} onChange={handleChange}/>
                <datalist id='edition-list'>
                  {
                    editions.map((edition) => <option key={edition.id} value={edition.year}/>)
                  }
                </datalist>
              </label>
              <label htmlFor='author'>
                <FormattedMessage id='app.author'/>
                <input name='author' id='author' list='author-list' value={author} onChange={handleChange}/>
                <datalist id='author-list'>
                  {
                    authors.map((author) => <option key={author.id} value={`${author.surname} ${author.name}`}/>)
                  }
                </datalist>
              </label>
              <input type='submit' value={intl.formatMessage({id: 'app.filter'})}/>
            </form>
          </div>
        </div>
        {
          loading ? <Loader/> : (
            serverError ? <ServerError/> : (
              artworksByYears ? (
                <Fragment>
                  <Pagination page={page} pageCount={Math.ceil(count / limit)} changePage={changePage}/>
                  <div className='Artworks__change-limit'>
                    <FormattedMessage id='app.elementsOnPage'/>
                    {
                      [10, 20, 50, 100].map(val => <button className={classNames('Artworks__limit-button', {'Artworks__limit-button--selected': val === limit})} key={val} onClick={() => changeLimit(val)}>{val}</button>)
                    }
                  </div>
                  {
                    artworksSorted.map((entry) => (
                      <Fragment key={entry[0]}>
                        <div className='Artworks__separator'>{entry[0]}</div>
                        <div className='Artworks__items'>
                          {
                            entry[1].map((artwork) => (
                              <Link to={Locations.Artwork.toUrl({id: artwork.id})} key={artwork.id} className='Artwork-item__wrapper'>
                                <div className='Artwork-item'>
                                  <div className='Artwork-item__photo'><img src={artwork.photoPath} alt=''/></div>
                                  <div className='Artwork-item__data'>
                                    <div className='Artwork-item__name'>{artwork.name}</div>
                                    <div>{artwork.authorName}</div>
                                    <div>{artwork.edition}, {artwork.sizes}</div>
                                  </div>
                                </div>
                              </Link>
                            ))
                          }
                        </div>
                      </Fragment>
                    ))
                  }
                </Fragment>
              ) : <div className='Artworks__none-found'><FormattedMessage id='app.noElements'/></div>
            )
          )
        }
      </div>
    );
  }
}

export default injectIntl(Artworks);
